import { imgs } from "../../assets/imgs";


export const Speakers = () => {
   const speakerCards = [
      {
         headShot: imgs.speakerCards.peterThiel,
         name: 'Peter Thiel',
         title: 'Co-Founder',
         company: 'PayPal',
         logo: imgs.logos.paypalLogo,
      },
      {
         headShot: imgs.speakerCards.uriLevine,
         name: 'Uri Levine',
         title: 'Co-Founder',
         company: 'Waze',
         logo: imgs.logos.wazeLogo,
      },
      {
         headShot: imgs.speakerCards.bobMetcalfe,
         name: 'Bob Metcalfe',
         title: 'Inventor of Ethernet',
         company: 'Founder of 3com',
         logo: imgs.logos.threeComLogo,
      },
      {
         headShot: imgs.speakerCards.jeffHoffman,
         name: 'Jeff Hoffman',
         title: 'Co-Founder',
         company: 'Priceline',
         logo: imgs.logos.pricelineLogo,
      },
      {
         headShot: imgs.speakerCards.ramShiram,
         name: 'Ram Shiram',
         title: 'Founding Board Member',
         company: 'Google',
         logo: imgs.logos.sherpaloLogo,
      },
      {
         headShot: imgs.speakerCards.ericLy,
         name: 'Eric Ly',
         title: 'Co-Founder, CEO',
         company: 'LinkedIn',
         logo: imgs.logos.linkedinLogo,
      },
      {
         headShot: imgs.speakerCards.nickSears,
         name: 'Nick Sears',
         title: 'Co-Founder',
         company: 'Android',
         logo: imgs.logos.androidLogo,
      },
   ]

   return (
      <div className='speakers'>
         <h1 className='theme-liner'>PAST KEYNOTE SPEAKERS</h1>
         <section className="speaker-cards">
            {speakerCards.map((speaker, idx) =>
               <div key={idx}>
                  <img src={speaker.headShot} alt="Img" />
                  <div>
                     <h2>{speaker.name}</h2>
                     <h3>{speaker.title}</h3>
                     <h3>{speaker.company}</h3>
                  </div>
                  <img src={speaker.logo} alt="Logo" />
               </div>
            )}
         </section>
      </div>
   )
}
