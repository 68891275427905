import { useEffect, useState } from "react";
import { useObserver } from "../../hooks/useObserver";

export const Stats = () => {

    const [containerRef, isVisible, wasVisible] = useObserver({
        root: null,
        rootMargin: "0px",
        threshold: 0.5
    })

    return (
        <div className='stats' ref={containerRef}>
            <h1 className='theme-liner'>Our Impact</h1>
            <section className={wasVisible ? 'slide-into-view' : ''}>
                <div>
                    <div>
                        <h3>1:1 Meetings</h3>
                        <h4>9,000+</h4>
                    </div>
                    <div>
                        <h3>Attendees</h3>
                        <h4>60,000+</h4>
                    </div>
                    <div>
                        <h3>Investors</h3>
                        <h4>2,300+</h4>
                    </div>
                    <div>
                        <h3>Startups</h3>
                        <h4>5,000+</h4>
                    </div>
                </div>
            </section>
        </div>
    )
}

