import { useEffect, useState } from "react";

export const ContactUs = () => {
    document.title = "youngStartup Ventures | ContactUs";
    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);
        script.addEventListener('load', () => {
            if (window.hbspt) {
                setIsLoading(false)
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "536350",
                    formId: "bdbe125c-3475-4d1b-9c0b-2217a3d9a5a9",
                    target: '#contactForm',
                })
            }
        })
    }, [])

    return (
        <div className='contact-us'>
            <h1 className='theme-liner'>Contact Us</h1>
            {isLoading && <div id="loader"></div>}
            {!isLoading && <div id="contactForm" />}
        </div>
    )
}