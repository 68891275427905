import { imgs } from "../../assets/imgs"

export const WhoWeAre = () => {

    return (
        <div className='who-we-are'>
            <h1 className='theme-liner'>Who We Are</h1>
            <section>
                <div>
                    <p>We host Venture Summits that drive innovation and investment.</p>
                    <p>Our summits unite visionary minds with seasoned investors, catalyzing groundbreaking ideas and fostering success in the Venture Capital landscape.</p>
                </div>
                <div>
                    <img src={imgs.home.whoWeAre2} alt="img" />
                </div>
            </section>
        </div>
    )
}