import { imgs } from "../../assets/imgs";


export const InvestorSection = () => {

   return (
      <div className='investors'>
         <h1 className='theme-liner'>TRUSTED BY TOP VCS</h1>
         <section>
            {Object.values(imgs.investors).map((img,idx) =>
               <img key={idx} src={img} alt="logo" />)}
         </section>
      </div>
   )
}
