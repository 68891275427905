import { useGlobalState } from '../hooks';
import { tracking } from '../utils/tracking';


export const UpcomingSummit = () => {
   const { eventsData } = useGlobalState()
   const isLoading = eventsData?.upcoming_events?.length > 0 ? false : true


   return (
      <div className='upcoming-summit'>
         <h1 className='theme-liner'>Upcoming</h1>
         {isLoading && <div id="loader"></div>}
         {!isLoading && eventsData.upcoming_events?.map((event, idx) =>
            <main key={idx}>
               <section>
                  <div>
                     <a href={event.page_url}>
                        <img src={event.portrait_image} alt="logo" />
                     </a>
                     <div>
                        <h3>{event.venue_location}</h3>
                        <h4>{event.dates_str}</h4>
                     </div>
                  </div>
               </section>
               <section>
                  <h2>{event.title}</h2>
                  <p>{event.description}</p>
                  <a className='btn' onClick={() => tracking(364)} href={event.page_url}>More Info</a>
               </section>
            </main>)}
      </div>
   )
}

