
export const Unsubscribe = () => {
   document.title = "youngStartup Ventures | Unsubscribe";

   return (
      <>
         <h1
            style={{
               textAlign: "center",
               color: "white",
               backgroundColor: "black",
               fontWeight: "700",
               fontSize:"2em",
               marginTop:"4em",
               padding:"1em",
            }}
         >
            UNSUBSCRIBE
         </h1>
            <iframe title='form' src="https://docs.google.com/forms/d/e/1FAIpQLSd2YIz86KYPkxwA2SZekGGfqxZ3sFCvdhrrLTjKU_bkcIqmAA/viewform?embedded=true" width={'100%'} height={"600"} frameBorder="0">Loading&#8230;</iframe>
      </>
   )
}
