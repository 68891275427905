import { useContext } from 'react'
import { Context } from '../state'

/** 
 * @typedef {Object} initialState
 * @property {Object} eventsData
 * @property {Object} ACTIONS
 * @typedef {initialState & { dispatch: React.Dispatch<any> }} context
 * @returns {context}
 */

export const useGlobalState = () => {
   const context = useContext(Context)
   return context
}