import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
const nestedRoutes = ['registration', 'agenda']

export function useScrollToTop() {
   const [isNested, setIsNested] = useState(false);
   const { pathname } = useLocation();
   const previousPathnameRef = useRef(null);
   const getIsNested = () => nestedRoutes.some(route =>
      previousPathnameRef.current.includes(route) &&
      pathname.includes(route)
   );
   useEffect(() => {
      if (previousPathnameRef.current) {
         setIsNested(getIsNested());
      }
      if (isNested) return
      window.scrollTo(0, 0);
      previousPathnameRef.current = pathname;
   }, [pathname]);
   return null
}