import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { FAQ, Home, AboutUs, Summits, ContactUs, Testimonials, Unsubscribe, Page404, } from './pages';
import { Footer, NavBar } from './components';
import { getData } from './utils/apiCalls';
import { useGlobalState, useScrollToTop } from './hooks';
import { ACTIONS } from './state';

function App() {
   const { dispatch } = useGlobalState()

   useEffect(() => {
      getData(`${process.env.REACT_APP_API_BASE_URL}/cwebsite/get_events`)
         .then((res) => {
            dispatch({ type: ACTIONS.SET, entity: 'eventsData', payload: res })
         })
   }, [])

   useScrollToTop()

   return (
      <div className="App">
         <NavBar />
         <Routes>
            <Route path='/' element={<Home />} />
            <Route path='about-us' element={<AboutUs />} />
            <Route path='summits' element={<Summits />} />
            <Route path='contact-us' element={<ContactUs />} />
            <Route path='testimonials' element={<Testimonials />} />
            <Route path='unsubscribe' element={<Unsubscribe />} />
            <Route path='*' element={<Page404 />} />
         </Routes>
         <Footer />
      </div >
   )
}

export default App
