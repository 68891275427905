import { tracking } from "../utils/tracking";
import { useGlobalState } from "../hooks";

export const PastEvents = ({ limit = 0 }) => {
    const { eventsData } = useGlobalState()
    const isLoading = eventsData.past_events?.length > 0 ? false : true


    return (
        <div className='past-events'>
            <h1 className='theme-liner'>Past Events</h1>
            <main>
                {isLoading && <div id="loader"></div>}
                {!isLoading && eventsData.past_events?.slice(0, limit || eventsData.length).map((summit, summitIdx) =>
                    <a key={summitIdx} className='event-card' onClick={() => tracking(365, summit.id)} href={summit.page_url}>
                        <img src={summit.portrait_image} alt="img" />
                        <div>
                            <h3>{summit.venue_location}</h3>
                            <h4>{summit.dates_str}</h4>
                        </div>
                    </a>
                )}
            </main>
        </div>
    )
}