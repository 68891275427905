import { imgs } from "../assets/imgs"

export const InfoSection = () => {
    return (
        <div className='info-section'>
            <section>
                <div>
                    <p>We host events.</p>
                    <p>Our Venture Summits connect the most innovative, early stage companies with active investors.</p>
                    <p>We understand how important it is to meet the right people and network with like-minded individuals.</p>
                </div>
                <div>
                    <img src={imgs.info.infoSection1} alt="Img" />
                </div>
            </section>
            <section>
                <div>
                    <img src={imgs.info.infoSection2} alt="Img" />
                </div>
                <div>
                    <p>Only the most cutting edge companies and relevant investors are invited to attend our Venture Summits.</p>
                    <p>We work hard to make sure that you have the opportunity to mingle with those in the know to forge the connections you need to succeed.</p>
                </div>
            </section>
        </div>
    )
}