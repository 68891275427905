import { Link } from 'react-router-dom';
import { tracking } from '../utils/tracking';
import { svgs } from '../assets/svgs';
import { useGlobalState } from '../hooks';

const socialMediaLinks = [
   {
      name: 'linkedin',
      icon: svgs.linkedin,
      link: "https://www.linkedin.com/company/youngstartup-ventures",
      buttonId: 297
   },
   {
      name: 'flickr',
      icon: svgs.fliker,
      link: "https://www.flickr.com/photos/youngstartup/",
      buttonId: 298
   },
   {
      name: 'youtube',
      icon: svgs.youtube,
      link: "https://www.youtube.com/user/youngStartup",
      buttonId: 299
   },
]

const aboutLinks = [
   { name: 'Home', link: '/', buttonId: 258 },
   { name: 'About Us', link: 'about-us', buttonId: 259 },
   { name: 'Summits', link: 'summits', buttonId: 261 },
   { name: 'Contact Us', link: 'contact-us', buttonId: 259 },
   { name: 'Testimonials', link: 'testimonials', buttonId: 260 },
]

export const Footer = () => {
   const { eventsData } = useGlobalState()
   const lastEvents = eventsData?.past_events?.slice(0, 6) || []
   const upcomingUrl = eventsData?.upcoming_events?.[0]?.page_url || ''
   const currentYear = new Date().getFullYear()


   return (
      <footer className="footer">
         <div className="main-footer">
            <div className="logo">
               {svgs.youngStartupsLogo}
               <h6>Where Innovation Meets Capital ™</h6>
            </div>
            <div className="follow-us">
               <h6>Follow Us</h6>
               <div className="icons">
                  {socialMediaLinks.map(media =>
                     <div onClick={() => tracking(media.buttonId)} key={media.name}>
                        <a href={media.link} rel='noreferrer' target='_blank'>{media.icon}</a>
                     </div>
                  )}
               </div>
            </div>
            <div className="about">
               <h6>About</h6>
               <ul >
                  {aboutLinks.map(about =>
                     <li onClick={() => tracking(about.buttonId)} key={about.name}>
                        <Link to={about.link}>{about.name}</Link>
                     </li>
                  )}
                  {upcomingUrl && <li>
                     <a href={upcomingUrl}>Upcoming Event</a>
                  </li>}
               </ul>
            </div>
            <div className="our-events">
               <h6>Our Events</h6>
               <ul>
                  {lastEvents.map((event, idx) =>
                     <li key={idx} onClick={() => tracking(event.buttonId)}>
                        <a href={event.page_url} target='_blank' rel='noreferrer'>{event.title}</a>
                     </li>
                  )}
               </ul>
            </div>
         </div>
         <hr />
         <div className="copy-rights">
            <h6>All Rights Reserved © 1999-{currentYear} - youngStartup Ventures</h6>
         </div>
      </footer>
   )
}
