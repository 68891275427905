import { Link } from "react-router-dom";
import { HomeBanner, UpcomingSummit, InvestorSection, FooterForm, PastEvents, WhoWeAre, Speakers } from "../components";

export const Home = () => {
   document.title = "youngStartup Ventures | Home";
   return (
      <div className='home'>
         <HomeBanner />
         <InvestorSection />
         <WhoWeAre />
         <UpcomingSummit />
         <PastEvents limit={3} />
         <div className='btn-container' >
            <Link style={{ width: '250px' }} to={'summits'} className='btn'>See More</Link>
         </div>
         <Speakers />
         <FooterForm />
      </div>
   )
}